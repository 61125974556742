@import "src/styles/abstracts/abstracts";
@import "src/styles/modules/typography";

.heading {
  margin-bottom: 40px;
}

.h2 {
  @extend h2;
  margin-bottom: 16px;
}

.h3 {
  @extend h3;
}

.h4 {
  font-weight: 500;
}

.form {
  text-align: right;
}

.input {
  margin-bottom: 20px;

  input {
    height: 44px;
  }
}

.forgotLink {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 40px;
  color: $hover;
  font-weight: 500;
}

.backLink {
  display: inline-flex;
  align-items: center;
  margin-top: 40px;
  color: $hover;
  font-weight: 500;
}

.iconBack {
  font-size: 24px;
  margin-right: 12px;
  color: $black;
}

.sendAgainContainer {
  display: inline-flex;
  align-items: center;
  margin-top: 40px;
  color: $hover;
  font-weight: 500;
}

.sendAgainLink {
  padding-left: 10px;
  cursor: pointer;
}

.errors {
  color: red;
  margin-top: 20px;
}
