$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../assets/fonts" !default;

$icon-crown: unquote('"\\e900"');
$icon-arrange: unquote('"\\e901"');
$icon-arrow-left: unquote('"\\e902"');
$icon-bell: unquote('"\\e903"');
$icon-calendar: unquote('"\\e904"');
$icon-check: unquote('"\\e905"');
$icon-clock: unquote('"\\e906"');
$icon-down: unquote('"\\e907"');
$icon-download: unquote('"\\e908"');
$icon-edit: unquote('"\\e909"');
$icon-eye: unquote('"\\e90a"');
$icon-eye-closed: unquote('"\\e90b"');
$icon-filter: unquote('"\\e90c"');
$icon-imports: unquote('"\\e90d"');
$icon-left: unquote('"\\e90e"');
$icon-lock: unquote('"\\e90f"');
$icon-log-out: unquote('"\\e910"');
$icon-mail: unquote('"\\e911"');
$icon-minus: unquote('"\\e912"');
$icon-pie-chart: unquote('"\\e913"');
$icon-pledge: unquote('"\\e914"');
$icon-plus: unquote('"\\e915"');
$icon-union: unquote('"\\e916"');
$icon-read: unquote('"\\e917"');
$icon-record-management: unquote('"\\e918"');
$icon-right: unquote('"\\e919"');
$icon-search: unquote('"\\e91a"');
$icon-settings: unquote('"\\e91b"');
$icon-sort-up: unquote('"\\e91c"');
$icon-sort-down: unquote('"\\e91d"');
$icon-process: unquote('"\\e91e"');
$icon-tool: unquote('"\\e91f"');
$icon-trash: unquote('"\\e920"');
$icon-up: unquote('"\\e921"');
$icon-users: unquote('"\\e922"');
$icon-valuation: unquote('"\\e923"');
$icon-warning: unquote('"\\e924"');
$icon-calculator: unquote('"\\e925"');
$icon-bank: unquote('"\\e926"');
$icon-close: unquote('"\\e927"');
$icon-pin: unquote('"\\e928"');
