@import "src/styles/abstracts/abstracts";

.container {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #{$bg-color} inset;
  }
  border-radius: $border-r;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 402px;

  input {
    padding-right: 0;
    border-radius: 0;
  }
}

.btn {
  @extend %btnreset;
  background-color: $white;
  padding: 8px;
  color: $black;
  font-size: 20px;
  line-height: 1;
  height: 36px;
}
