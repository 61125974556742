@import "src/styles/abstracts/abstracts";

.container {
  background-color: $bg-dark;
  width: $sidebar-wide;
  transition: width 0.5s;
  padding: 16px;
  display: flex;
  flex-direction: column;

  &.narrow {
    width: $sidebar-narrow;
  }
}

.header {
  display: flex;
  align-items: center;
  height: 64px;
  margin-bottom: 16px;
}

.logoText {
  color: $blue;
  font-weight: 700;
  font-size: 20px;
  display: block;
  line-height: 27px;
  text-align: center;
}

.nav {
  flex: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 -16px;
  padding: 16px;
}

.wideOnly {
  transition: 0.5s;

  .narrow & {
    opacity: 0;
    pointer-events: none;
  }
}

.logoWrapper {
  margin: 14px 0 0 14px;
}

.logo {
  width: 142px;
  max-width: 142px;
}

.narrow {
  .nav {
    margin: 0 -230px 0 -16px;
    padding: 16px 230px 16px 16px;
  }
}
