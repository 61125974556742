@import "src/styles/abstracts/abstracts";

.container {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
}

.left {
  display: flex;
  gap: 8px;
  margin-right: 8px;
}

.right {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-left: auto;
}

.filters {
  width: 100%;
}

.filterBtn {
  position: relative;
}

.marker {
  position: absolute;
  top: -3px;
  right: -3px;
}

.form {
  display: flex;
  width: 100%;
  padding: 4px;
  background-color: $gray2;
  border-radius: 8px;
  margin: -4px 0;
}

.openFormBtn {
  margin-right: 4px;
}
