@import "src/styles/abstracts/abstracts";

.container {
  position: relative;
  padding: 20px;
  color: $primary-color;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: $border-r;
  max-width: 450px;
  margin-left: auto;

  &.loading {
    background-color: $gray2;
  }

  &.done {
    background-color: $bg-success;
  }

  &.failed,
  &.failedData {
    background-color: $bg-alert;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.subTitle {
  margin-bottom: 8px;
}

.subTitleBold {
  margin-bottom: 8px;
  font-weight: 600;
  padding-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.titleFiles {
  @extend .title;
}

.alert {
  color: $alert;
}

.loader {
  min-width: 60px;
  margin-right: 20px;

  & + .content {
    width: calc(100% - 80px);
  }
}

.download {
  background-color: $white;
  margin-top: 10px;

  &:hover,
  &:focus {
    background-color: $bg-gray;
  }

  &:disabled {
    background-color: $white;
  }
}

.liftedUp {
  margin-top: -29px;
}
