@import "src/styles/abstracts/abstracts";

.container {
  text-align: center;
  position: absolute;
  top: 120px;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.loader {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #889498);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
