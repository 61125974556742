@import "src/styles/abstracts/abstracts";

.field {
  position: relative;
}

.error {
  input {
    border-color: $alert;
  }
}

.error {
  :global {
    .select {
      border: 1px solid $alert;
    }
  }
}

.errorText {
  color: $alert;
  font-size: 11px;
  position: absolute;
  margin-top: 1px;
  position: absolute;
  top: 100%;
  left: 12px;
}

.attention {
  color: $alert;
  position: absolute;
  right: 20px;
  bottom: 12px;
  font-size: 24px;
  background-color: $white;
}

.label {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
  cursor: default;
}

.checkboxListField {
  background-color: $white;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid $bg-color;
  flex: 1 1;
  overflow-y: auto;
}

.password {
  position: relative;

  .passwordInput {
    padding-right: 60px;
  }

  .showBtn {
    position: absolute;
    right: 0;
    width: 64px;
    color: $gray-mid;
  }
}

.textarea {
  height: 120px;
  padding-top: 16px;
  padding-bottom: 16px;
}
