@import "src/styles/abstracts/abstracts";

.block {
  background-color: $gray2;
  padding: 20px;
  border-radius: $border-r;
}

.blockInner {
  display: flex;
  gap: 16px 12px;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.rowNarrow {
  display: flex;
  align-items: flex-end;
  gap: 4px;
  width: 100%;
}

.field {
  flex: 1 1;

  :disabled {
    background-color: $bg-gray !important;
    color: $gray-mid;
  }
}

.label {
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.labelSmall {
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.16px;
  white-space: nowrap;
  padding-left: 12px;
  margin-bottom: 2px;
  display: block;
  color: $gray-mid;
}
