@import "src/styles/abstracts/abstracts";

.item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  font-size: 16px;
  font-weight: 600;
}

.btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 28px 0;
}
