@import "src/styles/abstracts/abstracts";

.container {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
}

.label {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.rangeRow {
  display: flex;
  flex-wrap: wrap;
  gap: 0 40px;
  align-items: center;

  .filterRowNarrow {
    @include m(1300) {
      width: auto;
      flex: 1 1;
    }
  }

  .label {
    @include m(1300) {
      margin-bottom: 0;
    }
  }
}

.fieldSm {
  flex: 0.6 1;
}

.assumptions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 28px 12px;

  @include m(1300) {
    grid-template-columns: 1fr 1fr;
  }
}

.inputField {
  input:disabled {
    background-color: $bg-gray !important;
    color: $gray-mid;
  }
}

.errorArea {
  color: red;
  margin-left: 50px;
}

.buttons {
  width: 252px;
  padding-bottom: 24px;
}

.generateBtn {
  width: 100%;
}
