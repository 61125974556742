@import "src/styles/abstracts/abstracts";

.container {
  display: flex;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.label {
  font-size: 12px;
  font-weight: 600;
  color: $gray-mid;
  text-transform: uppercase;
  margin-bottom: 16px;
  padding: 0 12px;
}

.folders {
  width: 300px;
  margin-right: 32px;
}

.folderList {
  @extend %listreset;
}

.listItem {
  padding: 12px;
}

.folder {
  display: flex;
  align-items: center;
  border-radius: $border-r;
  transition: 0.3s;

  &:hover,
  &.active {
    background-color: $gray2;
  }
}

.folderClickArea {
  margin-left: 12px;
  flex: 1 1;
  cursor: default;
  display: flex;
}

.folderName {
  flex: 1 1;
}

.subfunctionsPseudoWrap {
  flex: 1;
  padding-left: 20px;
}

.accessList {
  @extend %listreset;
  position: absolute;
  top: 32px;
  left: 330px;
  width: 330px;
  height: calc(100% - 32px);
  overflow-y: auto;
  padding-left: 20px;
  visibility: hidden;
  pointer-events: none;
  border-left: 1px solid $gray;
  padding-bottom: 40px;

  .active & {
    visibility: visible;
    pointer-events: visible;
  }
}

.btnRow {
  display: flex;
  gap: 12px;
  margin-top: 24px;

  button {
    width: 252px;
  }
}
