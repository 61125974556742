@import "src/styles/abstracts/abstracts";

.title {
  margin-bottom: 16px;
}

.form {
  margin-bottom: 60px;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 40px;
}

.field {
  width: calc(50% - 8px);
  min-width: 250px;
}

.notification {
  position: relative;
  padding: 20px;
  margin-top: -33px;
  color: $primary-color;
  font-size: 15px;
  border-radius: $border-r;
  max-width: 350px;
  margin-left: auto;
  background-color: $bg-success;
}

.notifTitle {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.changePassword {
  text-align: left;
}

.item {
  margin-bottom: 16px;
}
