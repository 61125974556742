@import "src/styles/abstracts/abstracts";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
  align-items: flex-end;
}

.widthHalf {
  width: calc(50% - 8px);
}

.item {
  width: calc(50% - 6px);
  min-width: 250px;
}

.itemOnlyOne {
  margin-right: 10px;
}

.subItems {
  display: flex;
  gap: 8px;
  margin-top: -8px;
}

.itemFile {
  width: 100%;
}

.label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  display: block;

  &.dates {
    margin-bottom: 5px;
  }
}

.textareaItem {
  resize: both;
  min-height: 100px;
}

.labelSmall {
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.16px;
  color: $gray-mid;
  white-space: nowrap;
  padding-left: 12px;
}

.wrapperAside {
  .item {
    width: 100%;
    margin-bottom: 16px;
  }
}

.wrapperCPanel {
  max-width: 100%;
  position: relative;

  &:not(:only-child) {
    .fieldsList {
      flex-wrap: wrap;
    }

    .item {
      position: relative;
      flex-grow: 1;
      flex-basis: 180px;

      &:nth-child(-n + 3) {
        flex-basis: 30%;
      }
    }
  }

  :global {
    .select {
      font-size: 12px;
      padding-top: 2px;
      padding-bottom: 2px;
      align-items: flex-end;
      white-space: nowrap;
    }

    .disabled {
      .icon-down {
        display: none;
      }
    }
  }

  .label {
    position: absolute;
    top: 3px;
    left: 12px;
    z-index: 1;
    margin-bottom: 0;
    color: $gray-mid;
    font-size: 8px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .labelSmall {
    position: absolute;
    top: 3px;
    z-index: 1;
  }
}

.wrapperCPanelOneRow {
  width: 100%;

  &:not(:only-child) {
    width: calc(100% - 40px);

    .fieldsList {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 32px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 36px;
        height: 200px;
        background-color: $bg-color;
        z-index: 1;
      }
    }
  }

  .itemOnlyOne {
    margin-right: 0;
  }
}

.fieldsList {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.item {
  position: relative;

  .size85 & {
    min-width: 85px;
  }

  .size120 & {
    min-width: 120px;
  }

  .size150 & {
    min-width: 150px;
  }

  .size180 & {
    min-width: 180px;
  }

  .size250 & {
    min-width: 250px;
  }

  .size300 & {
    min-width: 300px;
  }

  .size400 & {
    min-width: 400px;
  }
}

.wrapSearch {
  margin-bottom: 28px;
}

.searchRow {
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: 160px 1fr 100px;

  &.contract {
    grid-template-columns: 1fr 1fr;
  }
}

.withLabel {
  display: flex;
  margin-top: -8px;
  align-items: center;
  width: calc(50% - 6px);
  min-width: 500px;
}

.withLabel > span {
  min-width: calc(50%);
}

.labelInOneRowButton {
  display: flex;
  justify-content: flex-end;
  min-width: 500px;
}

.labelInOneRowButton > button {
  max-width: calc(50%);
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}
