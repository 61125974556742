@import "src/styles/abstracts/abstracts";

.layout {
  height: 100vh;
  display: flex;
  align-items: stretch;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - #{$sidebar-wide});
  flex: 1 1;
}
