$white: #ffffff;
$black: #11181f;
$dark-blue: #172028;
$blue-mid: #235c64;
$blue: #2bb9cd;
$gray: #d2d8da;
$gray2: #dce4e7;
$gray-mid: #657377;
$bg-gray: #eff2f6;

$alert: #ec0000;
$success: #03b66a;
$bg-alert: #eed9dd;
$bg-success: #cbe9e1;

$primary-color: $dark-blue;
$hover: $blue-mid;
$accent: $blue;
$bg-color: $bg-gray;
$bg-dark: $dark-blue;
$text-color: #022231;
$text-color-white: $white;
$placeholder: $gray-mid;

$font-size-base: 14px;
$line-height-base: 1.3;

$font: "Manrope", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
  Arial, sans-serif;

$h1: 50px;
$h2: 18px;
$h3: 16px;

$base-min-width: 320px;

$scroll_track: transparent;
$scroll_thumb: $black;
$scroll-width: 8px;

$border-r: 4px;

//Layout
$sidebar-narrow: 72px;
$sidebar-wide: 262px;

$header-height: 61px;
$control-panel-height: 56px;
$historyAsideHeader: 50px;
