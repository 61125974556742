html {
  box-sizing: border-box;
  font: #{$font-size-base}/#{$line-height-base} $font;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: $text-color;
  background: $bg-color;
  min-width: $base-min-width;
  overflow: hidden;
}

html,
body {
  @extend %scroll-wrap;
}

::-webkit-scrollbar {
  width: $scroll-width;
  height: $scroll-width;
}

::-webkit-scrollbar-thumb {
  background-color: $scroll_thumb;
}

::-webkit-scrollbar-track {
  background-color: $scroll_track;
}

// adaptive images
img {
  max-width: 100%;
  vertical-align: top;
  height: auto;
}
