@import "src/styles/abstracts/abstracts";

.container {
  overflow-y: auto;
  height: calc(100% - 120px);
}

.block {
  border-radius: $border-r;
  background-color: $white;
  padding: 20px;
  margin-bottom: 12px;
}

.title {
  margin-bottom: 16px;
}

.columns {
  column-count: 2;
  column-gap: 40px;
}

.item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 12px;
  break-inside: avoid-column;
}

.label {
  font-weight: 500;
  padding-right: 12px;
}

.value {
  background-color: $bg-gray;
  border-radius: $border-r;
  display: flex;
  align-items: center;
  padding: 9px 12px;
  min-height: 36px;
  justify-content: space-between;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-end;
  }
}
