@import "src/styles/abstracts/abstracts";
@import "src/styles/vendors/icomoon-style";

.container {
  display: flex;
  align-items: center;
}

.centered {
  justify-content: center;
}

.input {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  outline: none;
  cursor: pointer;

  &.square {
    border-radius: $border-r;
    border: 2px solid $primary-color;

    &:checked {
      @extend .icon-check;
      background-color: $primary-color;
      position: relative;

      &::before {
        @extend %icon;
        color: #fff;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 24px;
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  &.circle {
    border-radius: 50%;
    border: 1px solid $gray;

    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
      position: relative;

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $white;
        background-color: transparent;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    &.new {
      &:checked {
        background-color: $blue-mid;
        border-color: $blue-mid;
      }
    }
  }
}

.label {
  margin-left: 8px;
}
