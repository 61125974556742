@import "src/styles/abstracts/abstracts";

.btn {
  @extend %btnreset;
  background-color: transparent;
  cursor: pointer;
  padding: 0 6px;
  height: 24px;
}

.b1,
.b2,
.b3 {
  transition: 0.4s linear;
}

.b1 {
  d: path("M4 12H20");
}

.b2 {
  d: path("M7 6H17");
}

.b3 {
  d: path("M7 18H17");
}

.narrow {
  .b1 {
    d: path("M6 12L18 12");
  }

  .b2 {
    d: path("M4 6H20");
  }

  .b3 {
    d: path("M4 18H20");
  }
}
