@import "src/styles/abstracts/abstracts";

.sm {
  font-size: 16px;
}

.mid {
  font-size: 20px;
}

.navlink {
  composes: link from "../../layout/NavigationMenu/NavMenu.module.scss";
}

.navExpandIcon {
  composes: expandIcon from "../../layout/NavigationMenu/NavMenu.module.scss";
}

.navActive {
  composes: navActive from "../../layout/NavigationMenu/NavMenu.module.scss";
}

.navContent {
  composes: submenuWrapper from "../../layout/NavigationMenu/NavMenu.module.scss";
}

.navContentWrap {
  composes: navContentWrap from "../../layout/NavigationMenu/NavMenu.module.scss";
}
