@import "icomoon-variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fy4e6c")
      format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fy4e6c")
      format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fy4e6c##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"],
.icon,
%icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-crown {
  &:before {
    content: $icon-crown;
  }
}
.icon-arrange {
  &:before {
    content: $icon-arrange;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-down {
  &:before {
    content: $icon-down;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye-closed {
  &:before {
    content: $icon-eye-closed;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-imports {
  &:before {
    content: $icon-imports;
  }
}
.icon-left {
  &:before {
    content: $icon-left;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}
.icon-pledge {
  &:before {
    content: $icon-pledge;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-union {
  &:before {
    content: $icon-union;
  }
}
.icon-read {
  &:before {
    content: $icon-read;
  }
}
.icon-record-management {
  &:before {
    content: $icon-record-management;
  }
}
.icon-right {
  &:before {
    content: $icon-right;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-sort-up {
  &:before {
    content: $icon-sort-up;
  }
}
.icon-sort-down {
  &:before {
    content: $icon-sort-down;
  }
}
.icon-process {
  &:before {
    content: $icon-process;
  }
}
.icon-tool {
  &:before {
    content: $icon-tool;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-up {
  &:before {
    content: $icon-up;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-valuation {
  &:before {
    content: $icon-valuation;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator;
  }
}
.icon-bank {
  &:before {
    content: $icon-bank;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
