@import "src/styles/abstracts/abstracts";

.btn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  padding: 12px;
  color: $black;
}
