@import "src/styles/abstracts/abstracts";

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-r;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s all;
  outline: none;
  white-space: nowrap;
  border: 0;

  &:disabled {
    cursor: default;
    color: $gray-mid;
    background-color: $gray;
    pointer-events: none;
  }
}

.uppercase {
  text-transform: uppercase;
  font-weight: 600;
}

.long {
  width: 100%;
}

.large {
  height: 44px;
}

.normal,
.large,
.small {
  letter-spacing: 0.24px;
  font-weight: 600;
}

.normal {
  padding: 8px 48px;
  height: 36px;
}

.small {
  padding: 8px 12px;
  height: 36px;
}

.xs {
  height: 32px;
  padding: 8px;
}

.fill {
  background-color: $primary-color;
  color: $white;
  letter-spacing: 0.24px;
  padding-left: 12px;
  padding-right: 12px;

  &:hover,
  &:focus {
    background-color: $hover;
  }

  &:disabled {
    &.loading {
      background-color: $hover;
      color: $white;
    }
  }
}

.gray {
  background-color: $bg-gray;
  color: $primary-color;

  &:hover,
  &:focus {
    background-color: $gray;
  }
}

.grayDark {
  background-color: $gray2;
  color: $primary-color;

  &:hover,
  &:focus {
    background-color: $gray;
  }
}

.grayBright {
  background-color: $gray-mid;
  color: $white;

  &:hover,
  &:focus {
    background-color: $dark-blue;
  }

  &:disabled {
    background-color: $gray;
    color: $gray-mid;
  }
}

.white {
  background-color: $white;
  color: $gray-mid;

  &:hover {
    background-color: lighten($gray, 10%);
  }
}

.empty {
  border: 1px solid $primary-color;
  color: $primary-color;
  background-color: transparent;
  letter-spacing: 0.24px;
  font-weight: 600;
  padding-left: 12px;
  padding-right: 12px;

  &:hover,
  &:focus {
    color: $accent;
    border-color: $accent;
  }

  &:disabled {
    border: 1px solid $gray;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.invisible {
  padding: 0;
  border: 0;
  background-color: transparent;
  line-height: 1;
  height: auto;

  &:hover {
    color: $hover;
  }
}

.icon {
  font-size: 20px;

  &:not(:only-child) {
    padding-right: 8px;
  }
}

.title {
  @include animate(opacity visibility);
  display: block;
}
