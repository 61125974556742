@import "src/styles/abstracts/abstracts";

.item:not(:last-child) {
  margin-bottom: 32px;
}

.label {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: $gray-mid;
  text-transform: uppercase;
  display: block;
  margin-bottom: 16px;
}

.itemsRow {
  display: flex;
  gap: 16px;
}

.labelSmall {
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.16px;
  padding-left: 12px;
  color: $gray-mid;
}

.sticky {
  position: sticky;
  background-color: $bg-color;
  top: 0;
  z-index: 2;
  padding-bottom: 32px;
}

.gray {
  background-color: $gray-mid;
}
