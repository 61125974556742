@import "src/styles/abstracts/abstracts";

.container {
  display: flex;
  align-items: center;
  margin-bottom: 329px;
}

.subtitle {
  flex: 1;
  text-align: left;
}
