@import "src/styles/abstracts/abstracts";

.container {
  padding-top: 16px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid $gray;
  gap: 12px;
  height: 61px;
}

.heading {
  flex: 1;
  display: flex;
  align-items: center;
}

.path {
  font-weight: 500;
  color: $gray-mid;
  padding-right: 26px;
  position: relative;
  margin-top: 2px;
  display: inline-block;

  &:after {
    content: "/";
    position: absolute;
    right: 12px;
  }
}

.backBtn {
  margin-right: 8px;

  span {
    font-size: 24px;
  }
}
