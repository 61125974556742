@import "src/styles/abstracts/abstracts";

.layout {
  height: 100vh;
  display: flex;
  align-items: stretch;
}

.col {
  width: 50%;
}

.main {
  display: flex;
  justify-content: center;
  padding: 120px 0;
}

.content {
  max-width: 363px;
  width: 363px;
  text-align: center;
}

.header {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 2px solid $gray;
}

.logoText {
  color: $blue;
  font-weight: 700;
  font-size: 21px;
  display: block;
  line-height: 29px;
}

.aside {
  background-color: rgba(23, 32, 40, 0.2);
  background-image: url("../../../assets/images/auth-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 33vh 5%;
  color: $white;
}

.heading {
  text-align: center;
}
