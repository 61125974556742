@import "src/styles/abstracts/abstracts";

.containerVertical {
  overflow-y: auto;
  flex: 1;
  height: calc(100% - #{$control-panel-height});
  padding-bottom: 5px;
  z-index: 1;
}

.containerHorizontal {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
}

.table {
  border-collapse: collapse;
  font-size: 12px;
  width: 100%;

  th,
  td {
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.5;
  }

  thead {
    tr {
      position: relative;
      z-index: 3;
    }
  }

  th {
    position: sticky;
    top: 0;
    background-color: $gray2;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    box-shadow: inset 0 1px 0 $gray, inset 0 -1px 0 $gray;
    white-space: nowrap;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 46px;
    font-weight: 600;
  }

  tbody:nth-of-type(1) tr:nth-of-type(1) td {
    border-top: none;
  }

  td {
    border: 1px solid $gray;
    height: 38px;
    white-space: nowrap;
    text-align: center;
    background-color: $white;
  }
}

.fixedFirstCol {
  position: relative;

  th:first-child {
    z-index: 2;
    box-shadow: inset 1px 0 0 $gray, inset -1px 0 0 $gray, inset 0 1px 0 $gray,
      inset 0 -1px 0 $gray;
    border-left: 0;
    border-right: 0;
  }

  td:first-child {
    z-index: 1;
    background-color: $white;
    box-shadow: inset 1px 0 0 $gray, inset -1px 0 0 $gray;
    border-left: 0;
    border-right: 0;
  }

  th:first-child,
  td:first-child:not(.monthName) {
    position: sticky;
    left: 0px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    border-left: none;
  }

  td.monthName {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 10px 7px;
    box-shadow: none;
    background-color: $bg-color;
  }
}

.accountingStyle {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;

  span:first-child {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
  }
}
