@import "src/styles/abstracts/abstracts";

.headCell {
  text-align: center;
  cursor: default;
}

.headCellWithSort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  cursor: default;
}

.headCellLabel {
  display: block;
}

.selectAction {
  position: relative;
  width: 220px;

  :global {
    .select {
      font-size: 12px;
      padding-top: 2px;
      padding-bottom: 2px;
      align-items: flex-end;
      white-space: nowrap;
    }
  }

  .selectActionLabel {
    position: absolute;
    top: 3px;
    left: 12px;
    z-index: 1;
    margin-bottom: 0;
    color: $gray-mid;
    font-size: 8px;
    text-transform: uppercase;
  }
}

.actions {
  display: flex;
  justify-content: space-around;
}

.actionInCell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;

  :global {
    [class^="icon-"] {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  &:first-child:not(:only-child) {
    padding-right: 8px;
  }

  &:nth-child(2) {
    position: relative;
    padding-left: 8px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 1px;
      top: -10px;
      bottom: -10px;
      background-color: $gray;
    }
  }
}

.cellCheckbox {
  display: flex;
  justify-content: center;
}

.pinBtn {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  &:hover {
    color: $hover;
  }
}

.withPin {
  padding: 0 26px 0 0;
  transition: 0.3s padding;
}

.headCellWithPin {
  position: relative;

  &:hover,
  &.pinned {
    .pinBtn {
      opacity: 1;
      transition-delay: 0.2s;
    }

    .withPin {
      padding: 0 0 0 26px;
    }
  }
}