@import "src/styles/abstracts/abstracts";

.container {
  padding-top: 20px;
}

.title {
  margin-bottom: 16px;
}

.form {
  margin-bottom: 40px;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}

.field {
  width: calc(50% - 8px);
  min-width: 250px;
}
