@import "src/styles/abstracts/abstracts";

.container {
  position: relative;
  padding: 20px;
  color: $primary-color;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-radius: $border-r;
  max-width: 450px;
  margin-left: auto;
  background-color: $bg-alert;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.liftedUp {
  margin-top: -29px;
}
