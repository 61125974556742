@import "src/styles/abstracts/abstracts";

.wrapper {
  width: 100%;
  position: relative;

  .withLeftIcon {
    padding-left: 44px;
  }

  .withRightIcon,
  .error {
    padding-right: 44px;
  }

  .error {
    border-color: $alert;
  }

  .withLeftSymbol {
    padding-left: 20px;
  }

  .input.xs {
    height: 32px;
    font-size: 12px;
    padding-top: 9px;
  }

  .input {
    &:disabled {
      background-color: $gray2;
    }
  }
}

.leftIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.rightIcon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.leftSymbol {
  position: absolute;
  left: 12px;
  top: 51%;
  transform: translateY(-50%);

  &.xs {
    top: 20px;
  }
}

.amount {
  text-align: right;
}

.withClickIcon {
  cursor: pointer;
}

.errorText {
  position: absolute;
  bottom: -16px;
  left: 12px;
  color: $alert;
  font-size: 11px;
}

.labelSmall {
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.16px;
  color: $gray-mid;
  padding-left: 12px;
}
