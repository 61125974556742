@import "src/styles/abstracts/abstracts";
@import "src/styles/vendors/icomoon-style";

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.xs {
  height: 32px;
}

.value {
  padding-right: 30px;
  white-space: nowrap;
}

.open {
  box-shadow: 2px 2px 4px 0px rgba(92, 108, 129, 0.2);
}

.disabled {
  pointer-events: none;
  color: $gray-mid;
}

.placeholder {
  color: $placeholder;
}

.arrow {
  font-size: 24px;
  position: absolute;
  right: 8px;
  transition: 0.3s;

  &.rotated {
    transform: rotateX(180deg);
  }
}

.dropdown {
  position: absolute;
  color: $primary-color;
  right: 0;
  top: calc(100% + 1px);
  width: 100%;
  box-shadow: 2px 2px 4px 0px rgba(92, 108, 129, 0.2);
  background-color: $white;
  border-radius: $border-r;
  z-index: 3;
  max-height: 240px;
  overflow-y: auto;

  .option {
    min-height: 36px;
    padding: 0 40px 0 12px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.3s;
    white-space: nowrap;

    &:hover {
      background-color: $gray2;
    }

    &.active {
      @extend .icon-check;
    }

    &.active::before {
      @extend %icon;
      position: absolute;
      right: 8px;
      font-size: 24px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

    &:first-child {
      border-top-left-radius: $border-r;
      border-top-right-radius: $border-r;
    }
    &:last-child {
      border-bottom-left-radius: $border-r;
      border-bottom-right-radius: $border-r;
    }
  }
}

.fill {
  background-color: $primary-color;
  color: $white;
  letter-spacing: 0.24px;
  padding-left: 12px;
  padding-right: 12px;

  &:hover,
  &:focus {
    background-color: $hover;
  }

  &:disabled {
    &.loading {
      background-color: $hover;
      color: $white;
    }
  }
}

.gray {
  background-color: $bg-gray;
  color: $primary-color;

  &:hover,
  &:focus {
    background-color: $gray;
  }
}

.grayDark {
  background-color: $gray2;
  color: $primary-color;

  &:hover,
  &:focus {
    background-color: $gray;
  }
}

.min-50 {
  min-width: 50px;
}

.min-100 {
  min-width: 100px;
}

.min-200 {
  min-width: 200px;
}

.min-250 {
  min-width: 250px;
}