
.cellListContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.cellList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cellListItem {
}

.cellListButton {
}

.collapsed {
  .cellListItem:nth-child(n + 3) {
    display: none;
  }
}