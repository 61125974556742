@import "src/styles/abstracts/abstracts";

.container {
  padding: 12px;
  background-color: $gray2;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: $border-r;
}

.title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.file {
  font-size: 14px;
}
