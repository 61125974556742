@import "src/styles/abstracts/abstracts";

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 20px 0px;
}

.left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.pageSizeLabel {
  font-size: 15px;
  color: #444444;
  margin-right: 10px;
}

.pageTotalLabel {
  font-size: 15px;
  color: #444444;
  margin-right: 20px;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s all;
  background-color: #eff2f6;
  color: #444444;
  border-color: #657377;
  outline: none;
  white-space: nowrap;
  border-width: 1px;
  margin-left: -1px;
  margin-right: -1px;

  &:disabled {
    cursor: default;
  }
}

.select {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s all;
  background-color: #eff2f6;
  color: #444444;
  border-color: #657377;
  outline: none;
  height: 30px;
  white-space: nowrap;
  border-width: 1px;
  margin-right: 25px;
}

.pageInput {
  font-size: 14px;
  max-width: 60px;
  max-height: 30px;
  font-size: 14px;
  white-space: nowrap;
  border-width: 1px !important;
  border-color: #657377 !important;
  border-radius: 0px !important;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 5px;
  text-align: center;
}

.prevNextBtn {
  height: 30px;
}

.pageNumberBtn {
  width: 30px;
  height: 30px;
}

.selectBtn {
  background-color: #172028;
  color: #ffffff;
}