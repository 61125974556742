@import "src/styles/abstracts/abstracts";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.block {
  background: $white;
  border-radius: $border-r;
  padding: 16px;
}

.title {
  color: $gray-mid;
  margin-bottom: 8px;
}

.titleItem {
  &:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid $gray-mid;
  }
}
