@import "src/styles/abstracts/abstracts";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: $bg-color;
  padding-bottom: 16px;
}

.tab {
  @extend %btnreset;
  font-weight: 500;
  padding: 8px 0;
  margin-right: 20px;
  border-bottom: 2px solid transparent;

  &:not(.active) {
    color: $gray-mid;
    cursor: pointer;
  }
}

.active {
  color: $black;
  border-bottom-color: $blue;
  pointer-events: none;
}

.itemsContainer {
  height: calc(100% - #{$historyAsideHeader});
  overflow-y: auto;
  margin-right: -8px;
}

.item {
  background-color: $white;
  border-radius: $border-r;
  padding: 16px;
  margin-bottom: 12px;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  color: $placeholder;
  margin-bottom: 8px;
}

.itemLeft {
  margin-right: 16px;
}

.itemUser {
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid $gray;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}

.itemStatusInfo {
  color: $success;
  margin-bottom: 6px;

  &.alert {
    color: $alert;
  }
}

.itemFile {
  font-size: 12px;
  margin-right: 16px;
  display: inline-block;
  vertical-align: top;

  &:not(:only-child) {
    padding-right: 52px;
  }
}

.itemBottom {
  position: relative;
}

.downloadBtn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:disabled {
    background-color: $bg-gray;
  }
}
