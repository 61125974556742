@import "src/styles/abstracts/abstracts";

.btn {
  @extend %btnreset;
  cursor: pointer;
  opacity: 0.5;
  font-size: 20px;

  &.active {
    opacity: 1;
  }
}
