h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin: 0;
}

h1,
.h1 {
  font-size: $h1;
  font-weight: 800;
}

h2,
.h2 {
  font-size: $h2;
  font-weight: 700;
}

h3,
.h3 {
  font-size: $h3;
  font-weight: 600;
}

p {
  margin: 0 0 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

a {
  @extend %link;
}
