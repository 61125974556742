.wrapper {
  display: flex;
  padding-top: 24px;
  height: 100%;
}

.left:not(.only) {
  margin-right: 16px;
}

.right {
  padding-top: 25px;
}

.withContentBelow {
  height: auto;
}
