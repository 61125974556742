@import "src/styles/abstracts/abstracts";

.wideOnly {
  transition: 0.5s opacity;
}

.sections {
  @extend %listreset;
  padding-top: 11px;

  &:not(.narrow) {
    .sectionMenu {
      width: 230px;
    }
  }
}

.section {
  margin-bottom: 36px;
}

.sectionName {
  display: block;
  font-size: 12px;
  color: $gray-mid;
  padding-left: 48px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.sectionMenu {
  @extend %listreset;
}

.menuItem {
  position: relative;
  margin: 2px 0;

  > .link {
    color: $white;
    font-size: 15px;
    padding: 8px 0;
  }
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: $border-r;
  background-color: transparent;
  transition: 0.5s opacity;

  &:hover:not(.active) {
    .linkText {
      color: $blue;
    }
  }

  &.active {
    background-color: $hover;
    color: $white;
  }
}

.icon {
  font-size: 24px;
  margin: 0 8px;
}

.linkText {
  @extend .wideOnly;
  margin: 0 4px;
  white-space: nowrap;
  transition: 0.5s opacity;

  &.active {
    color: $blue;
  }
}

.expandIcon {
  @extend .wideOnly;
  flex: 1;
  display: flex;
  justify-content: flex-end;

  span {
    margin-right: 8px;
  }
}

.submenuWrapper {
  transition: 0.5s height, 0.5s opacity;
}

.submenu {
  @extend %listreset;
  width: 230px;
  transition: 0.5s height, 0.5s opacity;
  height: 100%;

  .link {
    @extend .wideOnly;
    padding: 7px 8px;
    margin-left: 36px;

    &:not(.active) {
      color: $white;
    }

    &:hover:not(.active) {
      color: $blue;
    }
  }
}

.narrow {
  .wideOnly {
    opacity: 0;
    pointer-events: none;
  }

  .link {
    border-radius: 8px;
  }

  .linkText {
    transition: 0s opacity;
  }

  .navContentWrap {
    opacity: 0;
  }

  .submenuWrapper {
    height: 0 !important;
    opacity: 0;
    pointer-events: none;
  }

  .navActive {
    @extend .active;
  }

  .submenu {
    transition: 0.5s opacity;
  }

  .menuItem:hover {
    z-index: 3;

    > .link {
      background-color: $hover;
      color: $white;
      transition: none;
    }

    .navContentWrap {
      display: block !important;
      animation: fade 0.3s forwards;
    }

    .linkText {
      opacity: 1;
      pointer-events: visible;
      padding: 12px 20px;
      margin: 0;
      background-color: $black;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 100%;
      color: $white;
      z-index: 1;
      transition: 0.3s opacity;
    }

    .submenuWrapper {
      position: absolute;
      top: 0;
      left: 100%;
      opacity: 1;
      pointer-events: visible;
      height: auto !important;
      transition: 0s opacity;
    }

    .submenu {
      background-color: $black;
      border-radius: 8px;
      padding: 40px 12px 8px;

      .submenuItem {
        margin: 4px 0;
      }

      .link {
        opacity: 1;
        pointer-events: visible;
        margin: 0;
      }
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
