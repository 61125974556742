@import "src/styles/abstracts/abstracts";

.wrapper {
  border-bottom: 1px solid #d2d8da;
  margin: 0 -40px 0 -20px;
}

.container {
  padding-top: 12px;
  padding-bottom: 12px;
}

.tabBtn {
  @extend %btnreset;
  color: $gray-mid;
  padding: 8px;
  margin-right: 8px;
  border-radius: $border-r;

  &:not(.active) {
    cursor: pointer;
  }
}

.active {
  background-color: $hover;
  color: $white;
}

.content {
  padding-top: 12px;
}
