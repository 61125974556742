@import "src/styles/abstracts/abstracts";
@import "src/styles/vendors/icomoon-style";

.wrapper {
  position: relative;
  @extend .icon-calendar;
  display: flex;
  align-items: center;
  font-family: $font;

  input {
    padding-right: 40px;
  }

  &::before {
    @extend %icon;
    position: absolute;
    right: 8px;
    font-size: 24px;
    z-index: 1;
    pointer-events: none;
  }

  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

.custom {
  width: 100%;

  &.xs {
    height: 32px;
    font-size: 12px;
    padding-top: 9px;
  }

  &:disabled {
    background-color: $gray2;
  }
}

:global {
  .react-datepicker__tab-loop {
    z-index: 2;
  }
}

.calendar {
  border: 0;
  padding: 12px;
  background-color: $white;
  box-shadow: 4px 10px 20px 0px rgba(92, 108, 129, 0.25);
  border-radius: $border-r;
  font-family: $font;
  font-size: 12px;
  font-weight: 600;
  cursor: default;

  :global {
    .react-datepicker__header {
      background-color: $white;
      border-bottom: 0;
    }
    .react-datepicker__current-month,
    .react-datepicker-year-header {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .react-datepicker__navigation {
      top: 18px;
    }

    .react-datepicker__navigation--previous {
      left: 10px;
    }

    .react-datepicker__navigation--next {
      right: 12px;
    }

    .react-datepicker__month-text {
      padding: 10px;
    }

    .react-datepicker__day-name {
      text-transform: uppercase;
      color: $gray-mid;
      width: 32px;
      margin: 0;
    }
    .react-datepicker__day {
      border-radius: 50%;
      width: 32px;
      padding: 4px 0;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      margin: 0;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--keyboard-selected {
      background-color: $accent;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected:not(
        .react-datepicker__month-text--selected
      ) {
      opacity: 0.5;
    }

    .react-datepicker__day--outside-month {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.open {
  :global {
    .react-datepicker-popper {
      transform: none !important;
      padding: 0 60px;
      background-color: $white;
      top: calc(100% + 24px) !important;
      right: 0 !important;
      left: auto !important;
      width: 500px;
    }

    .react-datepicker {
      box-shadow: none;
    }

    .react-datepicker__day-name {
      width: 49px;
    }

    .react-datepicker__day {
      width: 49px;
      border-radius: 50px;
    }
  }
}
