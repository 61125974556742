@import "src/styles/abstracts/abstracts";

.btn {
  margin-left: 12px;
  color: $gray-mid;

  & > span {
    padding: 2px 0;
    border-bottom: 1px dashed $gray-mid;
  }
}
