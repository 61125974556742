@import "src/styles/abstracts/abstracts";
@import "src/styles/modules/typography";

.wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  position: relative;
  width: 90%;
  max-width: 900px;
  overflow: hidden;
  background-color: $bg-color;
  padding: 80px 200px;
  max-height: 96vh;
  animation: fade 0.3s;
  z-index: 10;
  text-align: center;
  box-shadow: -4px 4px 21px 0px rgba(0, 0, 0, 0.18);
  border-radius: $border-r;
}

.content {
  margin-bottom: 50px;
}

.title {
  @extend h2;
  margin-bottom: 24px;
}

.subtitle {
  @extend h3;
}

.btnRow {
  display: flex;
  gap: 16px;
}

.overlay {
  .open & {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
