.r-select {
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
  min-height: auto !important;
  border: 0;

  .error & {
    border-color: $alert;
  }
}

.r-select-opener {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:has(~ .r-select-menu) {
    box-shadow: 2px 2px 4px 0px rgba(92, 108, 129, 0.2);
  }

  &.disabled {
    background-color: $gray2;
  }
}

.r-select-placeholder {
  color: $gray-mid;
}

.r-select-icon {
  font-size: 24px;
}

.r-select-menu {
  @extend %listreset;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 4px 0px rgba(92, 108, 129, 0.2);
  background-color: $white;
  border-radius: $border-r;
  top: calc(100% + 1px) !important;
  max-height: 259px;
  z-index: 2 !important;
}

.r-select-option {
  padding: 9px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $gray;
  }
}

.r-dropdownBtn {
  font-size: 24px;
}

.r-clearBtn {
  font-size: 16px;
  cursor: pointer;
}

.select__menu-notice {
  @extend .r-select-option;
}
