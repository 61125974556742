@import "src/styles/abstracts/abstracts";

.container {
  display: grid;
  gap: 12px;
  grid:
    "first second"
    "third third";
  margin-bottom: 40px;
}

.first {
  grid-area: first;
}

.second {
  grid-area: second;
}

.third {
  grid-area: third;
}

.thirdContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 52px;
}

.textarea {
  height: 116px;
  padding-top: 8px;
  padding-bottom: 12px;
}
