form,
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  border-style: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="number"],
textarea,
.select {
  @include appearance-none;
  @include placeholderColor($placeholder, 1);
  -webkit-border-radius: $border-r;
  border-radius: $border-r;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 0 12px;
  height: 36px;
  width: 100%;
  background: $white;
  color: $primary-color;
  cursor: default;

  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset;
  -webkit-text-fill-color: $primary-color !important;
  caret-color: $primary-color;
}

textarea {
  resize: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="search"]::-ms-reveal,
input[type="password"]::-ms-reveal,
input[type="search"]::-ms-clear,
input[type="password"]::-ms-clear {
  display: none;
}
