@import "src/styles/abstracts/abstracts";

.list {
  list-style: none;
  margin: 12px 0 0;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  background-color: $bg-color;
  border-radius: 12px;
}

.item {
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $gray2;
  border-radius: $border-r;
  width: 25%;
}

.title {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: $gray-mid;
  margin-right: 20px;
}

.value {
  font-size: 18px;
  font-weight: 700;
  color: $black;
}
